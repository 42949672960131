<template>
    <baidu-map class="map" ak="nZvSC0eO8OkUuaZLou1GAqSMcA0EtLYM" :center="center" :zoom="19">
        <bm-marker :position="center" :dragging="true" animation="BMAP_ANIMATION_BOUNCE"> </bm-marker>
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
    </baidu-map>
</template>
<script>
import { mapState } from 'vuex';
import BaiduMap from 'vue-baidu-map/components/map/Map.vue';
import bmMarker from 'vue-baidu-map/components/overlays/Marker.vue';
import BmNavigation from 'vue-baidu-map/components/controls/Navigation';
export default {
    name: 'map',
    data() {
        return {
            center: { lng: 121.63601, lat: 31.304581 }
        };
    },
    computed: {
        ...mapState(['userInfo'])
    },
    components: {
        BaiduMap,
        bmMarker,
        BmNavigation
    }
};
</script>
<style lang="scss" scoped>
.map {
    width: 600px;
    height: 380px;
}
</style>
